import "./i18n";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';


var all = document.getElementsByTagName("*");
var nodeFound = []
for (var i = 0, max = all.length; i < max; i++) {
	if (!all[i].hasAttribute("id")) continue;
	if (all[i].getAttribute("id").includes("root_oo2")) {
		nodeFound.push(all[i]);
	}
}


// ReactDOM.render(<React.StrictMode>
// 		<Suspense fallback={<div>Loading ……</div>}><App /></Suspense>
// 	</React.StrictMode>, document.getElementById('root'));

window.griddleReact = {};
for (var i = 0, max = nodeFound.length; i < max; i++) {
	window.griddleReact[nodeFound[i].getAttribute("id")] = ReactDOM.render(
		<React.StrictMode><Suspense fallback={<div>Loading ……</div>}><App dom={nodeFound[i]} /></Suspense>
		</React.StrictMode>, nodeFound[i]);
}