import React from 'react';
import './WarningCard.css'
import { useTranslation } from "react-i18next";

const WarningCard = () => {
    const { t } = useTranslation();
    return (
        <div className="styled_card">
            <p>{t('i18n.main.by_time_pool')}</p>
               
        </div>
    )}


export default WarningCard;