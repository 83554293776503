import React from 'react';
import '../../App.css';
import Air from '../../resources/images/air.svg'
import Water from '../../resources/images/water.svg'
import Conservation from '../../resources/images/rain-drop.svg'
import Solids from '../../resources/images/solids.svg'
import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";

const TargetPhases = () => {
    const { t } = useTranslation();
    return (
        <div className="phasesBox">
            <h1>{t('i18n.main.target_phases')}</h1>
            <div className="phases">
                <div className="cardBlue">
                    <img src={Water} alt="" />
                    <div>
                        <h3>{t('i18n.main.water')}</h3>
                        <p>{t('i18n.main.sewage_land_leachate')}</p>  
                    </div>
                </div>
                <div className="cardBlue">
                    <img src={Air} alt="" />  
                    <div>
                        <h3>{t('i18n.main.air')}</h3>
                        <p>{t('i18n.main.particulates_carbonmonoxide')}</p>
                    </div>
                </div>
                <div className="cardBlue">
                    <img src={Solids} alt="" />
                    <div>
                        <h3>{t('i18n.main.solids')}</h3>
                        <p>{t('i18n.main.plastics_metals_paper')}</p>
                    </div>
                </div>
                <div className="cardBlue">
                    <img src={Conservation} alt="" />
                    <div>
                        <h3>{t('i18n.main.conservation')}</h3>
                        <p>{t('i18n.main.water_electric_carbon')}</p> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TargetPhases;