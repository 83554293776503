import React, { useState, useEffect, useRef } from 'react';
// import ReactMapGL, { Marker, Popup, FlyToInterpolator } from 'react-map-gl';
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// import MapGL, {Popup, NavigationControl, FullscreenControl, ScaleControl, GeolocateControl} from 'react-map-gl';
import * as facilitiesData from "../resources/facilities.json";
import './Chart.css';
import * as d3 from 'd3-ease';
// import pointer from '../resources/images/akar-icons_circle-fill.svg';
// import mapboxgl from 'mapbox-gl';
// import PinMap from './PinMap';
const SIZE = 25;
// const ICON2 = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
//   c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
//   C20.1,15.8,20.2,15.8,20.2,15.7z`;
const ICON = 'M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z'

export default function Mapbox ({selectedMode, isBack}){
    const defaultViewport ={
        latitude: -22.9110137,
        longitude: -43.2093727,
        width: '100%',
        height: '100%',
        zoom: 3,
        transitionDuration: 5000,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: d3.easeCubic
    }

    

    const onLoaded =(map) =>{
        map.once('idle', function () { map.resize() });

    }

    const [viewport, setViewport] = useState ({
        ...defaultViewport
    });



    const [selectedFacility, setSelectedFacility] = useState(null);

    const flytoPoint = (facility) => {
                            setViewport({
                                ...viewport,
                                longitude: facility.geometry.coordinates[1],
                                latitude: facility.geometry.coordinates[0],
                                zoom: 10,
                                transitionDuration: 5000,
                                transitionInterpolator: new FlyToInterpolator(),
                                transitionEasing: d3.easeCubic
                            });
    };

    useEffect(() => {
        if (selectedMode != '') {
            setSelectedFacility(selectedMode);
            flytoPoint(selectedMode);
        }
        if (isBack === true) {
            setSelectedFacility(null);
            setViewport(defaultViewport);
        }
        const listener = e => {
            if(e.key === "Escape"){
                setSelectedFacility(null);
                setViewport(defaultViewport);
            }
        };
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        }
        
    }, [selectedMode, isBack]);

    const selected = (e, facility) => {
        e.preventDefault();
        setSelectedFacility(facility);
        flytoPoint(facility);
    }

    return (

        <div className="mapContainer">
            <ReactMapGL className="container"
            {...viewport}
            height="100%"
            width="100%" onViewportChange={(viewport) => setViewport(viewport)}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            onStyleLoad={(map)=>onLoaded(map)}
            // mapStyle="mapbox://styles/mapbox/dark-v9"
            mapStyle="mapbox://styles/alioit/ckq2fd8tx0vly17pjowndbm1e" 
            // mapStyle="mapbox://styles/mapbox/satellite-v9" 
            >
                {facilitiesData.features.map((facility) => (
                    <Marker  key={facility.properties.OBJECTID}
                            latitude={facility.geometry.coordinates[0]}
                            longitude={facility.geometry.coordinates[1]} >

                        <svg
                            height={SIZE}
                            viewBox="0 0 24 24"
                            style={{
                            cursor: 'pointer',
                            fill: '#50C878',
                            stroke: 'none',
                            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
                            }}
                            onClick={(e) => selected(e, facility)}
                        >
                            <path d={ICON} />
                        </svg>

                    </Marker>
                ))}
            </ReactMapGL>      
        </div>
    )
}