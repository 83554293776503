import React from 'react';
import '../../App.css';
import chartsSVG from '../../resources/images/charts.svg'
// import miniPieChart from "../miniPieChart";
import { VictoryPie, VictoryLabel } from 'victory';
import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";



const Distribution = () => {
    const { t } = useTranslation();
    return (
        <div className="foundationBox">
            <div className="header">
                <h2>{t('i18n.main.one_year')}</h2>
                <h1>{t('i18n.main.distribution')}</h1>
            </div>
            <div className="distributionBox">
                <div className="cards">
                    <div className="cardBlue">
                        <h3>{t('i18n.main.foundation')} - {t('i18n.main.percent_foundation')}</h3>
                        <ul>
                            <li className="darkBlue"><span>{t('i18n.main.projects')}({t('i18n.main.percent_projects')}):  </span>{t('i18n.main.sponsor_treatment')}</li>
                            <li className="blue"><span>{t('i18n.main.rd')}({t('i18n.main.percent_rd')}):  </span>{t('i18n.main.rd_monitoring')}</li>
                            <li className="white"><span>{t('i18n.main.operations')}({t('i18n.main.percent_operations')}):  </span>{t('i18n.main.operations_core')}</li>
                        </ul>
                    </div>
                    <div className="cardRed">
                        <h3>{t('i18n.main.investors')} - {t('i18n.main.percent_investors')}</h3>
                        <p>{t('i18n.main.assigned_founders')}</p>
                    </div>
                    <div className="cardYellow">
                        <h3>{t('i18n.main.network')} - {t('i18n.main.percent_network')}</h3>
                        <p>{t('i18n.main.transaction_distribution')}</p>
                    </div>
                </div>
                <div className="imgContainer">
                    <svg viewBox="0 0 400 400" className="pieShadow" width="100%" height="100%">
                            <VictoryPie colorScale={[ "#459fbf", "#ba2020", "#B8B500"]}
                              standalone={false}
                              width={400} height={400}
                              data={[
                                { x: "Foundation", y: 3, }, { x: "Investors", y: 2 }, { x: "Network", y: 5 }
                              ]}
                              labelRadius={70}
                              style={{ labels: { fontSize: 14, fill: "white" } }}
                              innerRadius={20}
                              padAngle={({ datum }) => datum.y}
                              cornerRadius={({ datum }) => datum.y * 1}
                
                            />

                          {/*  <miniPieChart data={[
                                { x: "ddd", y: 30 }, { x: "ccc", y: 20 }, { x: "aaa", y: 50 }
                              ]}/>*/}

                           <VictoryPie colorScale={[ "#459FBF",  "#2F77AC", "#194C87"]}
                              standalone={false}
                              origin={{x:330,y:90}}
                              width={200} height={200}
                              data={[
                                { x: "40", y: 40 }, { x: "20", y: 20 }, { x: "40", y: 40 }
                              ]}
                               labelRadius={30}
                               innerRadius={20}
                              style={{ labels: { fontSize: 12, fill: "white" } }}
                            />

                            <VictoryLabel
                              textAnchor="middle"
                              color="white"
                              style={{ fontSize: 16, fill: "white" }}
                              x={330} y={90}
                              text="30%"
                            />
                            <VictoryLabel
                              textAnchor="middle"
                              color="white"
                              style={{ fontSize: 16, fill: "white" }}
                              x={285} y={263}
                              text="20%"
                            />
                            <VictoryLabel
                              textAnchor="middle"
                              color="white"
                              style={{ fontSize: 16, fill: "white" }}
                              x={150} y={200}
                              text="50%"
                            />


                         {/*   <VictoryLabel
                              textAnchor="middle"
                              style={{ fontSize: 20 }}
                              x={200} y={200}
                              text="Pie!"
                            />*/}
                    </svg>
      {/*              <svg viewBox="0 0 200 200">
                           
                            <VictoryPie
                              standalone={false}
                              width={400} height={400}
                              data={[
                                { x: "Foundation", y: 30 }, { x: "Investors", y: 20 }, { x: "Network", y: 50 }
                              ]}
                               labelRadius={60}
                              style={{ labels: { fontSize: 20, fill: "white" } }}
                            />
                    </svg>
*/}
                </div>
            </div>





        </div>
    )
}

export default Distribution;