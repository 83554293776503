import React, {useState} from 'react';
import '../../App.css';
import Mapbox from '../Mapbox';
import Sidebar from '../Sidebar';

function Map() {
  const [selectedPoint, setSelectedPoint] = useState('')
  const [defaultBack, setDefaultBack] = useState('')
  return (
    <>
    <meta name="view-port" content="width-device-width, initial-scale=1, maximum-scale=1" ></meta>
    <div className='mapview'>
      <Sidebar setSelectedMode={setSelectedPoint} setBack={setDefaultBack}/>
      <Mapbox className="chart" selectedMode={selectedPoint} isBack={defaultBack}/>
    </div>
    </>
  );
}

export default Map;
