import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
{/*      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Company</h2>
            <Link to='/map'>Home</Link>
            <Link to='/'>Ecosystem</Link>
            <Link to='/'>About Us</Link>
            <Link to='/'>Support</Link>
            <Link to='/'>Whitepaper</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Get Involved</h2>
            <Link to='/'>Mine</Link>
            <Link to='/'>Stake</Link>
            <Link to='/'>Buy Token</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Social</h2>
            <Link to='/'>Twitter</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>LinkedIn</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Redddit</Link>
            <Link to='/'>Github</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Get in touch</h2>
            <Link to='/'>breathe@oxygenchain.earth</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Press</h2>
            <Link to='/'>Press Page</Link>
            <Link to='/'>press@oxygenchain.earth</Link>
          </div>
        </div>
      </div>*/}
      <div className="divider"></div>
{/*      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>2Oxigen © 2021</small>
          <small class='website-rights'>privacy policy</small>
          <small class='website-rights'>website Terms</small>
          <small class='website-rights'>Terms and conditions</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>*/}
    </div>
  );
}

export default Footer;
