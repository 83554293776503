import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import * as facilitiesData from "../resources/facilities.json";
import { useSSR } from 'react-i18next';
import { useTranslation } from "react-i18next";

function Sidebar({ setSelectedMode, setBack }) {

    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState(false)
    const [selectedPlant, setSelectedPlant] = useState('')
    const [toggleState, setToggleTab] = useState(1);

    const toggleTab = (index) => {
        setToggleTab(index);
    }

    const onFacilityClick = (mode) => {
        setSelectedMode(mode)
        setSelected(true)
        setSelectedPlant(mode)
        setBack(false)
    }
    const onBackClick = () => {
        setSelected(false)
        setBack(true)
    }
    useEffect(() => {
        const listener = e => {
            if (e.key === "Escape") {
                setSelected(false)
            }
        };
        window.addEventListener("keydown", listener);

        return () => {
            window.removeEventListener("keydown", listener);
        }
    }, []);
    if (selected) {
        return (
            <div className='sidemenu'>
                <div className='details_menu'>
                    <div className='backContainer'>
                        <button id="back" className="back-btn" onClick={() => onBackClick()}>
                            <i className="icon fas fa-arrow-left"></i> {t('i18n.main.back')}
                        </button>
                    </div>
                    <div className='detail_name'>{selectedPlant.properties.NAME}</div>
                    <div className='details-row'>
                        <div className='location'>
                            <i className="icon fas fa-map-marker-alt"></i>
                            <p className='address'>{selectedPlant.properties.ADDRESS}</p>
                        </div>
                        <div className='info'>{t('i18n.main.other')}: {selectedPlant.properties.DESCRIPTION}</div>
                    </div>
                </div>
                <div className='details' id="details">
                    <div className="tabsContainer">
                        <div className={toggleState === 1 ? "tab active-tab" : "tab"}
                            onClick={() => toggleTab(1)}>
                            {t('i18n.main.witnesses')}
                        </div>
                        <div className={toggleState === 2 ? "tab active-tab" : "tab"}
                            onClick={() => toggleTab(2)}>
                            {t('i18n.main.nearby')}
                        </div>
                        <div className={toggleState === 3 ? "tab active-tab" : "tab"}
                            onClick={() => toggleTab(3)}>
                            {t('i18n.main.rewards')}
                        </div>
                        <div className={toggleState === 4 ? "tab active-tab" : "tab"}
                            onClick={() => toggleTab(4)}>
                            {t('i18n.main.activity')}
                        </div>
                    </div>
                    <div className={toggleState === 1 ? "contentContainer active-content" : "contentContainer"}>
                        <div className="contentBox">
                            <p className="boxBody">{t('i18n.main.witnesses_not_available')}</p>
                        </div>
                    </div>
                    <div className={toggleState === 2 ? "contentContainer active-content" : "contentContainer"}>
                        <div className="contentBox">
                            <p className="boxBody">{t('i18n.main.nearby_not_available')}</p>
                        </div>
                    </div>
                    <div className={toggleState === 3 ? "contentContainer active-content" : "contentContainer"}>
                        <div className="contentBox">
                            <p className="boxTitle">{t('i18n.main.twentyfour')}</p>
                            <p className="boxData">0.00</p>
                            <p className="boxBody">Lorem impsum</p>
                        </div>
                        <div className="contentBox">
                            <p className="boxTitle">{t('i18n.main.thirtydays')}</p>
                            <p className="boxData">0.00</p>
                            <p className="boxBody">Lorem impsum</p>
                        </div>
                        <div className="contentBox">
                            <p className="boxBody">{t('i18n.main.nearby_not_available')}</p>
                        </div>
                    </div>
                    <div className={toggleState === 4 ? "contentContainer active-content" : "contentContainer"}>
                        <div className="contentBox">
                            <p className="boxBody">{t('i18n.main.activity_not_available')}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='sidemenu'>
                <div className='titlerow'>
                    <h1 id='count'>{facilitiesData.features.length}</h1>
                    <h3 id='subtitle'>{t('i18n.main.facilities')}</h3>
                </div>
                <div className='sidebar'>
                    <ul className='sidebar_list'>
                        {facilitiesData.features.map((facility) => {
                            return (
                                <li
                                    key={facility.properties.OBJECTID}
                                    className='sidebar_listrow'
                                    onClick={() => onFacilityClick(facility)}
                                >
                                    <div className='location'>
                                        <i className="icon fas fa-map-marker-alt"></i>
                                        <p className='address'>{facility.properties.ADDRESS}</p>
                                    </div>
                                    <div className='name'>{facility.properties.NAME}</div>
                                    <div className='info'>{t('i18n.main.other')}: {facility.properties.DESCRIPTION}</div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default Sidebar
