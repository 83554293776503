import React from 'react';
import './StyledButton.css';

const StyledButton = ({children , type, onClick, notEnabled}) => {
    // alert(enabled)
    return (
        <button disabled={notEnabled} onClick= {onClick} type={type} className="styled_button">
            {children}
        </button>
    )
}

export default StyledButton;