import React from 'react';
import '../../App.css';
import problemPNG from '../../resources/images/problem.png';
import Card from '../WarningCard';
import Logo2 from '../../resources/images/logo2.svg';

import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";

export default function Challenge() {
  const { t } = useTranslation();
  return (
    <div className="challenge">
        <div className="problem">
            <img className="theproblemPNG" src={problemPNG} alt="" />
            <div className="container">
                <h1>{t('i18n.main.problem')}</h1>
                <p>{t('i18n.main.more_80_percent')}</p>
                <Card>
                    <p>hardcoded in the card ... bla</p>
                </Card>
            </div>
        </div>
       
            <div className="solutionbox">
                <h1>{t('i18n.main.solution')}</h1>
                 <object className="gif" data="https://oxygenchain.earth/public/O2_molecule_light.gif">
                      <img src={Logo2} className="solutionLogo" alt="" />
                </object>
                
                {/*<img className="gif" src="https://oxygenchain.earth/public/O2_molecule_light.gif" alt="" />*/}
                <h2>{t('i18n.main.decentralized_treatement')}</h2>
                <p>

                    <object className="gif" data="https://oxygenchain.earth/public/oxygen_monitor_en.gif" >
                        <img src={Logo2} className="solutionLogo" alt="" />
                    </object>
                </p> 
            </div>
       
    </div>
)}
