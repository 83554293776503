import React from 'react';
import '../../App.css';
import '../../Animations.css'
import Challenge from '../pages/Challenge';
import Foundation from '../pages/Distribution';
import TargetPhases from '../pages/Phases';
import WhitePapers from '../pages/Papers';
import Contact from './Contact';
import Footer from '../Footer';
import StyledButton from '../StyledButton';
import translateDangerous from '../utils/utilities';
import { Link as Zelda } from "react-scroll";
import { useTranslation } from "react-i18next";
import { ReactComponent as BlockchainSVG } from '../../resources/images/Blockchain.svg';


const iiframe = '<iframe title="Tons of groundwaters being polluted" src="https://www.theworldcounts.com/embed/challenges/119?background_color=050f13&color=white&font_family=%22Helvetica+Neue%22%2C+Arial%2C+sans-serif&font_size=14" style="border: none" height="100" width="300"></iframe>'
function Home() {
  let _iframeIn = function () {
    return { __html: iiframe }
  }
  const { t } = useTranslation();
  return (
    <>
      <div className="home">
        <div className="columnLayout">
          {translateDangerous(t, 'i18n.main.a_decentralized_proto')}


          <Zelda
            rel="stylesheet"
            href=""
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            to='background'

          >
            <StyledButton>{t('i18n.main.action_button')}</StyledButton>
          </Zelda>

          <div className="polluted" dangerouslySetInnerHTML={_iframeIn()} />
        </div>
        <BlockchainSVG className="blockChainSVG" />

      </div>
      <Challenge />
      <Foundation />
      <TargetPhases />
      <WhitePapers />
      <Contact />
      <Footer />
    </>
  );
}
/* <Footer /> */
export default Home;
