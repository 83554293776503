import React from 'react';
import Document from '../../resources/images/document.svg'
import StyledButton from '../StyledButton';
import { Link } from 'react-router-dom';
import '../../App.css';
import translateDangerous from '../utils/utilities';
import { useTranslation } from "react-i18next";



const WhitePapers = () => {
    // function doIt(){ alert("hi")}
    const { t } = useTranslation();
    return (
        <div className="papersBox">
            <h1>{t('i18n.main.white_paper')}</h1>
            <div className="content">
                <div className="description">
                    <h3>{t('i18n.main.a_decentralized_protocol')}</h3>
                    <p className="whiteBody">{t('i18n.main.by')} <span>{t('i18n.main.white_paper_authors')} </span></p>
                     {translateDangerous(t,'i18n.main.waste_management_and_recycling_market')}
                       
                </div>
                <div className="downloadPapers">
                    <img src={Document} alt="" />
                    <StyledButton notEnabled={false} onClick={()=>{window.open("/whitepaper/oxygen_latest.pdf", "_blank")}}>{t('i18n.main.white_paper_download')}</StyledButton>
                </div>
            </div>
        </div>
    )
}

export default WhitePapers;