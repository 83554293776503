import React, { useState } from 'react';
import { Link as LinkRoute, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Link as Zelda } from "react-scroll";
import './Navbar.css';
import Logo from '../resources/images/logo.svg'
import { motion } from "framer-motion"


function Navbar(props) {
  // console.log(props)

  const root = props.props.dom;
  // console.log(props.props.dom.dataset?.key)
  // console.log(props.dom.dataset?.key)
  const { t, i18n } = useTranslation();
  const [click, setClick] = useState(false);
  const [language, setlanguage] = useState('en_us')

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const location = useLocation();

  function handleLangClick(lang) {
    i18n.changeLanguage(lang);
    setlanguage(lang)
    // alert(lang)
  }

  return (
    <>
      <nav className='navbar'>
        <motion.div className='navbar-container' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <motion.Link to='/' className='navbar-logo' onClick={closeMobileMenu} initial={{ x: -30 }} animate={{ x: 0 }} transition={{ type: 'spring', duration: 2 }}>
            <img src={Logo} alt="" />
          </motion.Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <motion.ul className={click ? 'nav-menu active' : 'nav-menu'} initial={{ x: 30 }} animate={{ x: 0 }} transition={{ type: 'spring', duration: 2 }}>
            <li className='nav-item'>
              {location.pathname !== '/map'
                ?
                <Zelda
                  rel="stylesheet"
                  href=""
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to='home'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.home')}
                </Zelda>
                :
                <LinkRoute
                  rel="stylesheet"
                  href=""
                  offset={-70}
                  duration={500}
                  to='/'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.home')}
                </LinkRoute>
              }
            </li>
            <li className='nav-item'>
              {location.pathname !== '/map'
                ?
                <Zelda
                  rel="stylesheet"
                  href=""
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to='challenge'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.challenge')}
                </Zelda>
                :
                <LinkRoute
                  rel="stylesheet"
                  href=""
                  offset={-70}
                  duration={500}
                  to='/'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.challenge')}
                </LinkRoute>
              }
            </li>
            <li className='nav-item'>
              {location.pathname !== '/map'
                ?
                <Zelda
                  rel="stylesheet"
                  href=""
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to='foundationBox'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.foundation')}
                </Zelda>
                :
                <LinkRoute
                  rel="stylesheet"
                  href=""
                  offset={-70}
                  duration={500}
                  to='/'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.foundation')}
                </LinkRoute>
              }
            </li>
            <li className='nav-item'>
              {location.pathname !== '/map'
                ?
                <Zelda
                  rel="stylesheet"
                  href=""
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to='phasesBox'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.target_phases')}
                </Zelda>
                :
                <LinkRoute
                  rel="stylesheet"
                  href=""
                  offset={-70}
                  duration={500}
                  to='/'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.target_phases')}
                </LinkRoute>
              }
            </li>
            <li className='nav-item'>
              {location.pathname !== '/map'
                ?
                <Zelda
                  itemProp
                  rel="stylesheet"
                  href=""
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to='contact'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.press')}
                </Zelda>
                :
                <LinkRoute
                  rel="stylesheet"
                  href=""
                  offset={-70}
                  duration={500}
                  to='/'
                  className='nav-links'
                  onClick={closeMobileMenu}
                >
                  {t('i18n.main.press')}
                </LinkRoute>
              }
            </li>
            <li className='nav-item'>
              <Zelda
                rel="stylesheet"
                href=""
                activeClass="active"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                to='map'
                className='nav-links'
              >
                <div className="languages">
                  <div onClick={() => handleLangClick("en_us")} >  EN </div>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <div onClick={() => handleLangClick("pt_br")} > PT </div>
                </div>
              </Zelda>
            </li>
            <li className='nav-item'>
            <Zelda
                rel="stylesheet"
                href=""
                activeClass="active"
                spy={true} smooth={true}
                offset={-70} duration={500} to='map' className='nav-links'
              >
              <div onClick={() => {window.open('https://oxygenchain.earth/exploreoxygen/index.html?local='+language, '_newtab')}} >  {t('i18n.main.map')} </div>
                 
              </Zelda>
                
              
            </li>
          </motion.ul>
        </motion.div>
      </nav>
    </>
  );
}

export default Navbar;