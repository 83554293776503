
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import Recaptcha from 'react-google-recaptcha'
// import { useNavigate } from '@reach/router'
import StyledButton from '../StyledButton';
import { useTranslation } from "react-i18next";


const RECAPTCHA_KEY =
  process.env.NODE_ENV === 'development'
    ? null
    : process.env.REACT_APP_RECAPTCHA_KEY  //export SITE_RECAPTCHA_KEY='6LcvT0cbAAAAAGqCdYs60twG6baph50RNfoySTzM'// 



const Contact = (props) => {

  const { t, i18n } = useTranslation();
  const [isThankyou, setIsThankyou] = useState(false)
  const [isEnabled, setIsEnabled] = useState(true)
  const [formData, setFormData] = useState({
          name: '',
          email: '',
          message: '',
          'g-recaptcha-response': '',
          
        })

 

  const { search } = useLocation()
  // const navigate = useNavigate()   
  const history = useHistory()   

  useEffect(() => {
  
    const params = new URLSearchParams(search)
    const message = params.get('message')
    if (message === 'thank-you') {
      setIsThankyou(true)
    } else {
      setIsThankyou(false)
      setIsEnabled(false)
    }
  }, [search])



  const handleRecaptchaChange = (value) => {

   
    setFormData((prev) => ({ ...prev, 'g-recaptcha-response': value}))
    setIsEnabled(true);
   
  
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formElement = e.target
    const emailSrc = formData.email;

    const gaccesscode = '6LcvT0cbAAAAAGqCdYs60twG6baph50RNfoySTzM';

    var messageBody = formData.message;
    let raw_name = formData.name.split(' ')
    const fname = raw_name[0]
    let lname = raw_name.shift(0)
    lname = raw_name.join(" ")

    try {
      const response = await fetch(`https://api.2oxygen.org/participation/contact?captcha=${gaccesscode}`, {
            method: 'POST',
            headers: {
              'x-api-key': 'L5Qh4cgepV3tamcPT9qan6YTrTHgBedR410oaW7K',
              'Content-Type': 'application/json',
            },body: JSON.stringify({
              first: fname, last: lname, email: emailSrc, message: messageBody
            }),

          })
      console.log(response)
      // navigate(formElement.getAttribute('action'))
      history.push(formElement.getAttribute('action'))
      // Redirect
    } catch (err) {
      console.error(err.message)
    }

  }

  return (
    <div className="background">
      <div className="contactContainer">
        <div className="contactInformation">
          <h1>{t('i18n.main.contact_information')}</h1>
          <p>{t('i18n.main.fill_form_send')}</p>
        </div>
        {isThankyou ? (
          <div className="formInput" className="contactForm">
            <label>{t('i18n.main.contact_message_sent')}
            </label>
          </div>
        ) : (
          <form
            action="?message=thank-you#contact"
            name="contact"
            method="POST"
            className="contactForm"
            onSubmit={handleSubmit}
          >
            <div className="formInput">
              <label htmlFor="">{t('i18n.main.full_name')}</label>
              <input type="text"
                name="name"
                placeholder="Full Name"
                required onBlur={handleChange} />

            </div>
            <div className="formInput">
              <label htmlFor="">{t('i18n.main.email')}</label>
              <input type="email"
                name="email"
                required onBlur={handleChange}
              />
            </div>
            <div className="formInput">
              <label htmlFor="">{t('i18n.main.message')}</label>
              <textarea name="message" id="" cols="30" rows="3" required onBlur={handleChange} ></textarea>
            </div>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '26px 0px',
              borderTop: '1px solid #e4dddd',
              borderBottom: '1px solid #e4dddd'
            }}>
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                render="explicit"
                onChange={handleRecaptchaChange}
                onExpired={() => {
                  setIsEnabled(false);
                }}
                theme="dark"
              />
            </div>
            {/* {RECAPTCHA_KEY && (
              <Recaptcha sitekey={RECAPTCHA_KEY}
                onChange={handleRecaptchaChange}
              />
            )} */}
             {isEnabled ? (
            <StyledButton  
              type='submit'>{t('i18n.main.send_message')}
             
              </StyledButton>
             ) : (

              <button type="submit" className="styled_button" disabled={true}>VERIFY CAPTCHA</button>
             
          )}

          </form>
        )}
      </div>
    </div>
  )
}

export default Contact;