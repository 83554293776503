import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { useTranslation } from "react-i18next";
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, BrowserRouter } from 'react-router-dom';
import Map from './components/pages/Map';
// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


function App(props) {
  const { t, i18n } = useTranslation();
  console.log(props)

  return (
    <>
      <Router basename={`${process.env.PUBLIC_URL}/`}>
        <Navbar props={props} />
        <Switch>
          {/* <Route path='/' exact component={Home} /> */}
          <Route path='/' exact render={(props) => (
            <Home {...props} />
          )} />
          <Route path='/map' component={Map} />
        </Switch>
      </Router>
    </>
  );
}
// <Route path='/Challenge' component={Challenge} />
export default App;
